<template>
<div class="m-page">
    <Header :menu-show="0"></Header>
    <div class="page-body">
        <keep-alive exclude="dashboard-m,payment-m,withdrawal-m,fund-m,center-m,transaction-m,userFeedback-m,Feedback-m,teamwork-m"><router-view></router-view></keep-alive>
    </div>
</div>
</template>
<script>
import Header from "@/components/mobile/header";
export default {
    name:'account-m',
    components: {
        Header,
    },
}
</script>
<style lang="less" scoped>
.m-page {
    min-height:100vh; background:#f4f5f6;
    .page-body {}
}
</style>